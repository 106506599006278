.pay-rolls-single {
    .pay-rolls-single-menu {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100px;
        padding: 0 20px;
        background-color: var(--sally-background-layer-1);
        border-bottom: 1px solid var(--sally-card-border);
        box-shadow: 0 2px 6px 0 rgba(28, 75, 110, 0.04);

        .h1 {
            margin: 0;
            line-height: 100px;
            vertical-align: middle;
            color: var(--sally-text);

            a {
                display: inline-block;
                padding-left: 20px;
                font-size: 14px;
            }
        }
        .title-menu {
          display: flex;
            min-width: 300px;
            min-height: 72px;
            margin-top: 14px;
            text-align: right;
                align-items: flex-end;
                    flex-direction: row-reverse;

            .ant-btn {
                vertical-align: middle;
                margin-left: 10px;

                &.ant-btn-primary {
                    min-width: 200px;
                    margin-left: 30px;
                }
                &.ant-btn-secondary {
                    margin-left: 30px;
                }
            }
            .loading-overlay {
                top: 0;
                right: -10px;
                bottom: 0;
                left: -10px;
            }
            .payroll-buttons-text {
                position: absolute;
                right: 0;
                bottom: -16px;
                font-size: 12px;
            }
            .ant-btn + p, p + .ant-btn {
                margin-left: 10px;
            }
            & > span {
                display: block;
                padding-top: 4px;
                font-size: 12px;
            }
        }
    }
    .pay-rolls-single-main {
        margin-right: auto;
        margin-left: auto;
        padding: 105px 0 0 0;

        .pay-roll-overview {
            .ant-card.ant-card-bordered {
                .ant-card-body {
                    $line-height: 28px;
                    $vpadding: 25px;
                    min-height: $line-height + $vpadding * 2;
                    padding-top: $vpadding;
                    padding-bottom: $vpadding;

                    .ant-row > * {
                        line-height: $line-height;
                        font-size: 14px;
                        color: var(--sally-paragraph-text);

                        strong {
                            font-weight: 500;
                            color: var(--sally-text);
                        }
                    }
                    .user-image {
                        margin-right: 20px;
                        vertical-align: middle;
                    }
                    a, button, span.fake-a {
                        display: inline-block;

                        .disabled {
                            cursor: not-allowed;
                        }

                        & + a, & + button, & + span.fake-a {
                            margin-left: 20px;
                        }
                    }
                }
            }
            .pay-roll-toggle {
                display: inline-block;
                padding: 0 20px 0 0;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
            .pay-roll-card-container {
                overflow-y: hidden;
                max-height: 0;
                margin: 0 -30px -25px -30px;
                -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                &.pay-roll-card-container-visible {
                    overflow-y: visible;
                    max-height: 25000px;
                }
            }
            .pay-roll-card-buttons {
                margin: 15px 15px 0;
            }
            .pay-roll-table {
                & > p {
                    margin: 0 30px;
                }
                .pay-roll-table-actions {
                    width: 1px;
                    white-space: nowrap;
                }
                .ant-spin-nested-loading {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
                .ant-table {
                    max-height: 1500px;
                    overflow-y: scroll;
                    margin: 0;
                }
            }
        }
        .pay-roll-details {
            .h2 {
                margin-bottom: 15px;
            }
            .ant-row {
                line-height: 32px;
                font-size: 14px;
                font-weight: 500;

                em {
                    font-style: normal;
                    font-weight: 400;
                }
                .icon {
                    margin: 0 10px;
                    vertical-align: middle;
                }
                & + .ant-row {
                    margin-top: 0;
                }
                & > * {
                    &:first-child {
                        font-weight: 400;
                    }
                    &:last-child {
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .ant-btn-icon {
                    margin-left: 4px;

                    .icon {
                        margin: unset;
                    }
                }
            }
            .disposition-date {
                display: flex;
            }
            .approved-by-at {
                margin-top: -8px;
                display: block;
                font-style: italic;
                font-weight: normal;
            }
        }
        .pay-roll-timeline {
            .h2 {
                margin-bottom: 30px;
            }
        }
        .warnings-container {
            display: none;
            margin-top: 10px;

            &.visible {
                display: block;
            }
            > .ant-alert > .ant-alert-message > .ant-row > .ant-col {
                font-size: inherit;
                line-height: inherit;
                color: inherit;
            }
        }
    }
}
.pay-roll-tax-report {
    .h1 {
        margin-top: 0;
    }
    .h3 {
        margin-bottom: -20px;
    }
    .ant-row {
        & > * {
            font-size: 14px;

            strong {
                font-weight: 500;
            }
        }
        & + .ant-row {
            margin-top: 30px;
        }
    }
}
.pay-roll-transfers {
    .h1 {
        margin-top: 0;
    }
    .pay-roll-table {
        margin: 0 -30px -25px -30px;

        .pay-roll-table-actions {
            width: 1px;
            white-space: nowrap;
        }
        .ant-spin-nested-loading {
            margin-top: 30px;
            margin-bottom: 0;
        }
        .ant-table {
            border: none;
            border-radius: 0;
            box-shadow: none;

            .ant-table-body {
                > table {
                    padding: 0;
                }
                .ant-table-thead {
                    > tr > th {
                        padding: 11px 20px;
                        font-size: 14px;
                        font-weight: 400;

                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }
                .ant-table-tbody {
                    > tr > td {
                        padding: 28px 20px;

                        .pay-roll-limited-width {
                            overflow-x: hidden;
                            display: block;
                            width: 150px;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            @media (min-width: 1200px) {
                                width: 180px;
                            }
                            @media (min-width: 1440px) {
                                width: auto;
                            }
                        }
                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }
            }
            .ant-table-footer {
                padding: 17px 30px;
                text-align: right;
            }
        }
    }
}
.ant-popover.context-menu-popover {
    margin-left: 250px;

    .ant-popover-inner {
        a {
            display: block;

            button {
                width: 100%;
                text-align: left;
            }
        }
        button {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}
.field-number-tool-tip {
    cursor: help;
    border-bottom: 1.5px var(--sally-form-label) dotted;
}

.back-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.1rem;
  }
