.contracts-add {
    max-width: 1240px;
    margin: auto;
    .contracts-add-overview {
        .employee-search {
            margin: 30px 0 0 0;
            padding-right: 118px;
            padding-left: 120px;
            background-position: 38px center;
            background-repeat: no-repeat;
            background-size: 39px 49px;

            label {
                display: block;
                margin-bottom: 5px;
            }
            + * {
                margin-top: 30px;
            }
        }
    }
}
.contracts-remove-row {
    text-align: right;

    .icon {
        margin: 9px -5px 0 0;
        cursor: pointer;
    }
}
.contracts-move-row {
    display: flex;
    flex-direction: column;

    span {
        margin: 0;
        .icon {
            margin: 0;
            width: 15px;
            cursor: pointer;
        }
    }
}
.contracts-add-row {
    display: inline-block;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 500;
    color: var(--sally-link);
    cursor: pointer;

    .icon {
        margin: 0 2px -5px 0;

        .icon-fill {
            fill: var(--sally-link);
        }
    }
}
.contract-toggle-item {
    position: relative;
    width: 100%;
    padding: 16px 0 16px 45px;
    border: 1px solid var(--sally-subcard-border);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.02);
    font-size: 14px;
    font-weight: 500;
    color: var(--sally-text);
    cursor: pointer;

    &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 10px;
        display: block;
        width: 25px;
        height: 25px;
        margin-top: -12.5px;
        border: 1px solid #e1e6eb;
        border-radius: 50%;
    }
    &.contract-toggle-item-active {
        border-color: var(--sally-green);
        box-shadow: 0 2px 6px 0 rgba(55, 169, 20, 0.06);

        &:before {
            background-color: var(--sally-green);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 10px 10px;
            border-color: #318f14;
        }
    }
    @media (min-width: 1200px) {
        padding-left: 65px;

        &:before {
            left: 25px;
        }
    }
}
.contracts-optional-description.ant-input-group {
    display: flex;
    align-content: stretch;

    & > * {
        display: block;

        &:first-child {
            flex: 1 0 auto;
            width: auto;
        }
        &.contracts-optional-input {
            width: 35px;
            cursor: pointer;

            &:not(.contracts-optional-description-active):not(:has(input:focus)) {
                color: transparent;

                input {
                    color: transparent;
                }

                .ant-input-suffix {
                    right: 9px;

                    .icon-fill {
                        color: var(--sally-input-text);
                    }
                }
            }

            &:has(input:focus), &.contracts-optional-description-active {
                width: 50%;
                cursor: text;

                .ant-input-suffix {
                    display: none;
                }
            }
        }
    }
}
.future-contract-row {
    padding-top: 10px;

    h2 {
        font-size: 13pt;
    }

    .future-contract-row-change {
        display: block;
        &.increase {
            color: var(--sally-green-shaded);
        }
        &.decrease, &.remove {
            color: var(--sally-red-shaded);
        }
    }
}
body .ant-form .contract-worktime-week-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ant-row.ant-form-item {
        margin: 0;
    }

    .contract-worktime-week-row-remove {
        cursor: pointer;
    }
}